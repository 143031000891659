import { useState } from "react";
// import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
// import { DatePicker } from "@mui/x-date-pickers";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Dialog,
  Card,
  Box,
  Stack,
  // Chip,
  IconButton,
  Tooltip,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import MDTypography from "components/MDTypography";
// import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import CloseIcon from "@mui/icons-material/Close";
// import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
// import HomeIcon from "@mui/icons-material/Home";
// import PhoneIcon from "@mui/icons-material/Phone";
// import EmailIcon from "@mui/icons-material/Email";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
// import WorkIcon from "@mui/icons-material/Work";
// import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import MDBox from "components/MDBox";
// import ReactWhatsapp from "react-whatsapp";
// import individualHp10 from "../data/individualHp10";

const MAccordion = styled((props) => <Accordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderTop: 0,
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  })
);

const MAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "inherit",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    color: "inherit",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const MAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default function DialogProfileView({
  openDialogView,
  model,
  // handleClickEditDialog,
  insertNum,
  dispatchCloseDialogView,
  sektor,
  jenama,
  handleOpenConfirmationDialog,
  // newGrade,
  // icNum,
  // newUnit,
  // newDept,
  // job,
  // birthday,
  // address,
  // tel,
  // email,
  // employDate,
  // checkUpDate,
}) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  // const [newYear, setNewYear] = useState(new Date().getFullYear());

  // const handleDateChange = (newValue) => {
  //   setNewYear(newValue?.$y);
  // };

  // function handleResetYear() {
  //   setNewYear(new Date().getFullYear());
  // }

  function handleResetExpanded() {
    setExpanded(false);
  }

  // const data = individualHp10(icNum, newYear);

  // const memoizedData = useMemo(() => data, [icNum, newYear]);

  // const age = (new Date().getTime() - birthday) / (3600 * 24 * 365 * 1000);

  // const year = Math.floor(age);

  // const month = Math.round((age - year) * 12);

  return (
    <Dialog open={openDialogView} maxWidth="xl" fullWidth>
      <Card>
        <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
          <Stack spacing={0.5}>
            <MDTypography variant="h5">
              {jenama} {model}
            </MDTypography>
            <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
              <MDTypography variant="body2" color="text">
                {insertNum}
              </MDTypography>
              <MDTypography variant="body2" color="text">
                {sektor}
              </MDTypography>
            </Stack>
            {/* <MDTypography variant="body2" color="text">
              huhu huhu
            </MDTypography>
            <MDTypography variant="body2" color="text">
              Unit huhu, huhu
            </MDTypography> */}
          </Stack>
          <Stack>
            <Tooltip title="Close" placement="right-start" enterTouchDelay={0}>
              <IconButton
                color="error"
                onClick={() => {
                  dispatchCloseDialogView();
                  // handleResetYear();
                  handleResetExpanded();
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit profile" placement="right-start" enterTouchDelay={0}>
              <IconButton color="inherit" onClick={handleOpenConfirmationDialog}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Box>
        <MAccordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <MAccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <MDTypography variant="button" color="text">
              {expanded ? "Sembunyi perincian" : "Tunjuk perincian"}
            </MDTypography>
          </MAccordionSummary>
          <MAccordionDetails>
            <Stack spacing={2} direction="row" mb={1}>
              {/* <MDBox>
                <Tooltip title="Email" placement="left-start" enterTouchDelay={0}>
                  <EmailIcon color="text" />
                </Tooltip>
              </MDBox> */}
              <MDBox>
                <MDTypography variant="body2" color="text">
                  Nombor siri tiub X-ray : KS9834234IX
                </MDTypography>
              </MDBox>
            </Stack>
            <Stack spacing={2} direction="row" mb={1}>
              {/* <MDBox>
                <Tooltip title="Email" placement="left-start" enterTouchDelay={0}>
                  <EmailIcon color="text" />
                </Tooltip>
              </MDBox> */}
              <MDBox>
                <MDTypography variant="body2" color="text">
                  Nombor aset : KKM-2349873293741
                </MDTypography>
              </MDBox>
            </Stack>
            <Stack spacing={2} direction="row" mb={1}>
              {/* <MDBox>
                <Tooltip title="Report duty" placement="left-start" enterTouchDelay={0}>
                  <WorkIcon color="text" />
                </Tooltip>
              </MDBox> */}
              <MDBox>
                <MDTypography variant="body2" color="text">
                  Nombor panel kawalan : XEX764321I-Y
                </MDTypography>
              </MDBox>
              {/* <MDTypography variant="body2" color="text">
                {new Date(employDate).toString().split(" ")[2]}{" "}
                {new Date(employDate).toString().split(" ")[1]}{" "}
                {new Date(employDate).toString().split(" ")[3]}
              </MDTypography> */}
            </Stack>
          </MAccordionDetails>
        </MAccordion>
        {/* <ReportsLineChart chartOnly chart={memoizedData} color="success" /> */}
        {/* <Box
          sx={{ display: "flex", justifyContent: "flex-end", paddingBottom: 2, paddingRight: 2 }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Year"
              views={["year"]}
              // onChange={handleDateChange}
              defaultValue={dayjs()}
              sx={{ width: { lg: "20%", md: "20%", sm: "30%", xs: "40%" } }}
            />
          </LocalizationProvider>
        </Box> */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 2, py: 1, bgcolor: "background.default" }}
        >
          {/* {active ? (
            <Chip
              label="Active"
              color="success"
              deleteIcon={<DoneIcon />}
              onDelete={handleDelete}
            />
          ) : (
            <Chip
              label="Inactive"
              color="error"
              deleteIcon={<CloseIcon />}
              onDelete={handleDelete}
            />
          )} */}
        </Stack>
      </Card>
    </Dialog>
  );
}
