import { styled, InputLabel, StepLabel, Stepper, TableRow } from "@mui/material";
import MDInput from "components/MDInput";

const NewMDInput = styled(MDInput)(({ theme }) => ({
  width: "100%",
  "& :-webkit-autofill": {
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: theme.palette.text.main,
    WebkitBoxShadow: `0 0 0px 1000px ${theme.palette.autofillBackground.main} inset`,
  },
  "& .MuiInputLabel-root": {
    paddingBottom: "2px",
  },
}));

const NewInputLabel = styled(InputLabel)(() => ({
  paddingBottom: "2px",
}));

const NewStepper = styled(Stepper)(() => ({
  backgroundImage: "none",
  boxShadow: "none",
}));

const NewStepLabel = styled(StepLabel)(({ theme }) => ({
  "& .MuiStepLabel-alternativeLabel": {
    color: `${theme.palette.text.main}`,
  },
  "& .MuiStepLabel-label.Mui-active": {
    color: `${theme.palette.text.secondary} !important`,
  },
  "& .MuiStepLabel-label.Mui-completed": {
    color: `${theme.palette.success.main} !important`,
  },
}));

const NewTableRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.background.default,
    cursor: "pointer",
  },
}));

export { NewMDInput, NewInputLabel, NewStepLabel, NewStepper, NewTableRow };
