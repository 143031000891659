import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";

function PageNotFound() {
  return (
    <MDBox pt={6} pb={3}>
      <MDTypography textAlign="center">Page not found!</MDTypography>
    </MDBox>
  );
}

export default PageNotFound;
