import logo from "assets/images/logo.svg";
// import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import { createContext, useState, useContext, useMemo } from "react";

const SplashContext = createContext({
  showSplash: () => {},
  hideSplash: () => {},
  isShown: true,
});

export function SplashProvider({ children }) {
  const [isShown, setIsShown] = useState(true);

  const value = useMemo(
    () => ({
      showSplash: () => {
        setIsShown(true);
      },
      hideSplash: () => {
        setIsShown(false);
      },
      isShown,
    }),
    [isShown]
  );

  return (
    <SplashContext.Provider value={value}>
      {isShown ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: { xs: "15%", sm: "10%", md: "5%" } }}>
            <img src={logo} alt="FD" width="100%" />
          </Box>
        </div>
      ) : null}
      {children}
    </SplashContext.Provider>
  );
}

export const useSplash = () => {
  const context = useContext(SplashContext);

  if (!context) {
    throw new Error("Must be in SplashProvider!");
  }

  return context;
};
