import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import authorsTableData from "layouts/tables/data/authorsTableData";
// import MDButton from "components/MDButton";
// import { Link } from "react-router-dom";
import { CircularProgress, Backdrop, Grid, Card } from "@mui/material";
import React, { useEffect, useContext, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { doc, deleteDoc } from "firebase/firestore";
import { UserContext } from "context/UserContext";
// import SuccessfulTransferProfileSnackBar from "components/SnackBar/SuccessfulTransferProfileSnackBar";
import DialogProfileView from "./components/DialogProfileView";
import DialogConfirmation from "./components/DialogConfirmation";
// import DialogProfileEdit from "./components/DialogProfileEdit";
import OnlineStatusSnackbar from "../onlineStatusSnackbar/onlineStatusSnackbar";
import { db } from "../../firebase-config";
import { closeDialogView } from "../../slices/deleteSlice";

function TableComponent() {
  const { columns, rows } = authorsTableData();
  return (
    <DataTable
      search={{ fields: ["searchable"] }}
      table={{ columns, rows }}
      // entriesPerPage={{ defaultValue: 100 }}
      isSorted
      showTotalEntries
      noEndBorder
      canSearch
      placeholder="Search anything..."
    />
  );
}

const TableComponentMemo = React.memo(TableComponent);

function Tables() {
  useEffect(() => {
    document.title = "Fastlane Dynamic - Radiation Devices List";
  }, []);
  const { machines } = useContext(UserContext);
  // console.log(machines);
  const dispatch = useDispatch();
  const { openDialogView } = useSelector((state) => state.delete);
  const { id } = useSelector((state) => state.idDelete);
  // const [showSuccessfulTransferProfileSnackBar, setShowSuccessfulTransferProfileSnackBar] =
  //   useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  // const handleClickConfirmationOpen = () => {
  //   setOpenConfirmationDialog(true);
  // };

  // const handleCloseConfirmationDialog = () => {
  //   setOpenConfirmationDialog(false);
  // };

  // const [openDialogEdit, setOpenDialogEdit] = useState(false);

  // const handleClickEditDialog = () => {
  //   setOpenDialogEdit(true);
  // };

  // const [backdropStatus, setBackdropStatus] = useState(false);

  // const onClickBackDropStatus = () => {
  //   setBackdropStatus(true);
  // };

  // const closeBackDropStatus = () => {
  //   setBackdropStatus(false);
  // };

  // const height = { height: 44 };

  const specificMachines = machines.filter((e) => e.id === id);

  // https://www.youtube.com/watch?v=uVPtYLGPL80&t=405s&ab_channel=Logicism
  const handleDelete = useCallback(async (idFB) => {
    const docRef = doc(db, "machines", idFB);
    await deleteDoc(docRef);
  }, []);

  // const initialStates = {
  //   newName: specificMachines[0]?.name,
  //   newEmail: specificMachines[0]?.email,
  //   newAddress: specificMachines[0]?.address,
  //   newEmployDate: specificMachines[0]?.emplDate?.seconds * 1000,
  //   newMedCheckUp: specificMachines[0]?.nextCheckUp?.seconds * 1000,
  //   newJob: specificMachines[0]?.job,
  //   newGrade: specificMachines[0]?.grade,
  //   newDept: specificMachines[0]?.dept,
  //   newUnit: specificMachines[0]?.unit,
  //   newHealthScrNo: specificMachines[0]?.healthScrNo,
  //   newStatus: specificMachines[0]?.active,
  //   newTelNo: specificMachines[0]?.tel,
  //   newHosp: specificMachines[0]?.hosp,
  // };

  // const immutableState = { ...initialStates };
  // const memoedInitialStates = useMemo(() => immutableState, [JSON.stringify(machines)]);
  // const [state, setState] = useState(memoedInitialStates);

  // console.log(state.newEmployDate);
  // console.log(state.newMedCheckUp);

  // const handleCloseEditDialog = () => {
  //   setState(memoedInitialStates);
  //   setOpenDialogEdit(false);
  // };

  // useEffect(() => {
  //   setState(immutableState);
  // }, [specificMachines[0]?.icNum, openDialogEdit, openDialogView, machines]);

  // useEffect(() => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     newName: specificMachines[0]?.name,
  //     newEmail: specificMachines[0]?.email,
  //     newAddress: specificMachines[0]?.address,
  //     newHealthScrNo: specificMachines[0]?.healthScrNo,
  //     newGrade: specificMachines[0]?.grade,
  //     newDept: specificMachines[0]?.dept,
  //     newUnit: specificMachines[0]?.unit,
  //     newTelNo: specificMachines[0]?.tel,
  //     newStatus: specificMachines[0]?.active,
  //     newEmployDate: specificMachines[0]?.emplDate?.seconds * 1000,
  //     newMedCheckUp: specificMachines[0]?.nextCheckUp?.seconds * 1000,
  //   }));
  // }, [JSON.stringify(machines), openDialogEdit, openDialogView, machines]);

  // const birthdayDate = new Date(specificMachines[0]?.birthday?.seconds * 1000);

  // const birthdate = [
  //   birthdayDate.getFullYear(),
  //   `0${birthdayDate.getMonth() + 1}`.slice(-2),
  //   `0${birthdayDate.getDate()}`.slice(-2),
  // ].join("-");

  // const reportDutyDate = new Date(state.newEmployDate);

  // const reportDutyDateValue = [
  //   reportDutyDate.getFullYear(),
  //   `0${reportDutyDate.getMonth() + 1}`.slice(-2),
  //   `0${reportDutyDate.getDate()}`.slice(-2),
  // ].join("-");

  // const nextCheckUpDate = new Date(state.newMedCheckUp);

  // const nextCheckUpDateValue = [
  //   nextCheckUpDate.getFullYear(),
  //   `0${nextCheckUpDate.getMonth() + 1}`.slice(-2),
  //   `0${nextCheckUpDate.getDate()}`.slice(-2),
  // ].join("-");

  // const submit = async (e) => {
  //   e.preventDefault();
  //   onClickBackDropStatus();
  //   await updateDoc(doc(db, "users", id), {
  //     name: state.newName,
  //     email: state.newEmail,
  //     address: state.newAddress,
  //     tel: state.newTelNo,
  //     emplDate: Timestamp.fromDate(new Date(new Date(state.newEmployDate).setHours(0, 0, 0, 0))),
  //     nextCheckUp: Timestamp.fromDate(new Date(new Date(state.newMedCheckUp).setHours(0, 0, 0, 0))),
  //     job: state.newJob,
  //     grade: state.newGrade,
  //     dept: state.newDept,
  //     unit: state.newUnit,
  //     healthScrNo: state.newHealthScrNo,
  //     active: state.newStatus,
  //     hosp: state.newHosp,
  //   }).then(() => {
  //     if (state.newHosp !== initialStates.newHosp) {
  //       setShowSuccessfulTransferProfileSnackBar(true);
  //       dispatch(closeDialogView());
  //     }
  //     handleCloseEditDialog();
  //     closeBackDropStatus();
  //   });
  // };

  return (
    <DashboardLayout>
      <OnlineStatusSnackbar />
      {/* <SuccessfulTransferProfileSnackBar
        showSuccessfulTransferProfileSnackBar={showSuccessfulTransferProfileSnackBar}
        onClose={() => setShowSuccessfulTransferProfileSnackBar(false)}
      /> */}
      <DialogProfileView
        openDialogView={openDialogView && specificMachines.length !== 0}
        jenama={specificMachines[0]?.modalityTypes}
        insertNum={specificMachines[0]?.insertNum}
        // handleClickEditDialog={handleClickEditDialog}
        handleDelete={handleDelete}
        dispatchCloseDialogView={() => {
          dispatch(closeDialogView());
        }}
        handleOpenConfirmationDialog={() => setOpenConfirmationDialog(true)}
        negeri={specificMachines[0]?.negeri}
        sektor={specificMachines[0]?.sektor}
        // newName={state.newName}
        // newGrade={specificMachines[0]?.grade}
        // icNum={specificMachines[0]?.icNum}
        // newUnit={state.newUnit}
        // newDept={state.newDept}
        // job={specificMachines[0]?.job}
        // birthday={specificMachines[0]?.birthday.seconds * 1000}
        // address={specificMachines[0]?.address}
        // tel={specificMachines[0]?.tel}
        // email={specificMachines[0]?.email}
        // employDate={specificMachines[0]?.emplDate.seconds * 1000}
        // checkUpDate={specificMachines[0]?.nextCheckUp.seconds * 1000}
      />

      <DialogConfirmation
        openConfirmationDialog={openConfirmationDialog && specificMachines.length !== 0}
        handleCloseConfirmationDialog={() => setOpenConfirmationDialog(false)}
        dispatchCloseDialogView={() => {
          // handleCloseConfirmationDialog();
          setOpenConfirmationDialog(false);
          dispatch(closeDialogView());
          // handleCloseEditDialog();
          handleDelete(id);
        }}
      />
      {/*
      <DialogProfileEdit
        openDialogEdit={openDialogEdit && specificUser.length !== 0}
        submit={submit}
        height={height}
        handleNameChange={(e) => setState({ ...state, newName: e.target.value })}
        newName={state.newName || ""}
        icNum={specificUser[0]?.icNum}
        birthdate={birthdate}
        sex={specificUser[0]?.sex}
        newEmail={state.newEmail || ""}
        handleEmailChange={(e) => {
          setState({ ...state, newEmail: e.target.value });
        }}
        newAddress={state.newAddress || ""}
        handleAddressChange={(e) => setState({ ...state, newAddress: e.target.value })}
        newTelNo={state.newTelNo || ""}
        handleNewTelNo={(e) => setState({ ...state, newTelNo: e.target.value })}
        reportDutyDateValue={reportDutyDateValue}
        handleReportDutyDateValueChange={(e) =>
          setState({ ...state, newEmployDate: e.target.value })
        }
        nextCheckUpDateValue={nextCheckUpDateValue}
        handleNextCheckUpDateValueChange={(e) =>
          setState({ ...state, newMedCheckUp: e.target.value })
        }
        newJob={state.newJob || ""}
        handleNewJobChange={(e) => setState({ ...state, newJob: e.target.value })}
        newGrade={state.newGrade || ""}
        handleNewGradeChange={(e) => setState({ ...state, newGrade: e.target.value })}
        newDept={state.newDept || ""}
        handleNewDeptChange={(e) => setState({ ...state, newDept: e.target.value })}
        newUnit={state.newUnit || ""}
        handleNewUnitChange={(e) => setState({ ...state, newUnit: e.target.value })}
        newHealthScrNo={state.newHealthScrNo || ""}
        handleNewHealthScrNoChange={(e) => setState({ ...state, newHealthScrNo: e.target.value })}
        newStatus={state.newStatus}
        handleNewStatusChange={(e) => setState({ ...state, newStatus: e.target.checked })}
        handleClickConfirmationOpen={handleClickConfirmationOpen}
        handleCloseEditDialog={() => handleCloseEditDialog()}
        handleWorkplaceChange={(e) => setState({ ...state, newHosp: e.target.value })}
        workplace={state.newHosp || ""}
      />  */}

      <DashboardNavbar />
      {/* <MDBox pt={3} pb={3}>
        <MDButton
          size="medium"
          color="info"
          variant="gradient"
          component={Link}
          to="/radas-penyinaran/daftar"
        >
          <Icon>create</Icon>&nbsp; Daftar
        </MDButton>
      </MDBox> */}
      <MDBox pt={6} pb={3} position="relative">
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: "absolute",

            width: "100%",
            height: "100%",
          }}
          open={!machines.length}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 9999,
          }}
          // open={backdropStatus}
          open={false}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card sx={{ marginTop: 8 }}>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Radiation Devices
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <TableComponentMemo />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Tables;
