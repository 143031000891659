/* eslint-disable import/no-unresolved */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
// import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
// import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
// import rtlPlugin from "stylis-plugin-rtl";
// import { CacheProvider } from "@emotion/react";
// import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import { useSplash } from "SplashProvider";
// Images
import { AuthContextProvider } from "context/AuthContext";
import brandWhite from "assets/images/logo.svg";
// import brandDark from "assets/images/logo.png";

// import Dashboard from "layouts/dashboard";

// import ProtectedRoute from "ProtectedRoute";
// import Payment from "layouts/payment";
// import PricingList from "layouts/pricing-lists";
import Basic from "./layouts/authentication/sign-in/index";
import PageNotFound from "./PageNotFound";

export default function App() {
  const sleep = (t = 1000) =>
    new Promise((resolve) => {
      setTimeout(resolve, t);
    });
  const { hideSplash, isShown } = useSplash();
  useEffect(() => {
    (async () => {
      // Simulating some background work
      await sleep(4000);
      // As soon as the backgorund work finishes, hide the splash and display the home screen
      hideSplash();
    })();
  }, []);
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    // transparentSidenav,
    // whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  // const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  // useMemo(() => {
  //   const cacheRtl = createCache({
  //     key: "rtl",
  //     stylisPlugins: [rtlPlugin],
  //   });

  //   setRtlCache(cacheRtl);
  // }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // const navigate = useNavigate();

  // // Checking if the token is already existed
  // useEffect(() => {
  //   const authToken = sessionStorage.getItem("Auth Token");

  //   if (authToken) {
  //     navigate("/home");
  //   }

  //   if (!authToken) {
  //     navigate("/login");
  //   }
  // }, []);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.flatMap((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.type === "accordion" && route.anak) {
        return route.anak.map((nestedRoute) => (
          <Route
            exact
            path={nestedRoute.route}
            element={nestedRoute.component}
            key={nestedRoute.key}
          />
        ));
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  function getAllRoutes(data) {
    const result = [];

    data.forEach((item) => {
      // Add route if it exists
      if (item.route) {
        result.push(item.route);
      }

      // Recursively process anak array if it exists
      if (item.anak && Array.isArray(item.anak)) {
        result.push(...getAllRoutes(item.anak));
      }
    });

    return result;
  }

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      {/* <Icon fontSize="small" color="inherit">
        settings
      </Icon> */}
      <WhatsAppIcon />
    </MDBox>
  );

  const shouldShowSideBar = () => getAllRoutes(routes).includes(pathname);

  return isShown ? null : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && shouldShowSideBar() && (
        <>
          <Sidenav
            color={sidenavColor}
            // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brand={brandWhite}
            brandName="adiation Dose Database"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            word-wrap="break-word"
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <AuthContextProvider>
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<PageNotFound />} />
          <Route path="/sign-in" element={<Basic />} />
        </Routes>
      </AuthContextProvider>
    </ThemeProvider>
  );
}
